import clsx from 'clsx'
import React from 'react'
import UilTimes from '@iconscout/react-unicons/dist/icons/uil-times'
import ClearIconButton from './ClearIconButton'

type AlertVariant = 'success' | 'danger' | 'info' | 'subtle'

const baseStyles = 'flex gap-4 w-full px-4 py-2 rounded-md border'

const variantStyles: Record<AlertVariant, string> = {
  success: 'border-green-600 bg-green-200',
  danger: 'border-red-800 bg-red-200',
  info: 'border-blue-800 bg-blue-200',
  subtle: 'border-slate-800/50 text-md',
}

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  variant: AlertVariant
  onClose?: () => void
}

function Alert({ variant, className, children, onClose, ...rest }: React.PropsWithChildren<Props>) {
  return (
    <div
      {...rest}
      className={clsx(baseStyles, variantStyles[variant], className)}
    >
      <div className="flex-1">
        {children}
      </div>
      {onClose && <ClearIconButton aria-label="Close" onClick={onClose}>
        <UilTimes />
      </ClearIconButton>}
    </div>
  )
}

export default Alert
